import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EarthIcon } from "../../Assets/Icons/Earth.svg"
import ContactsIcon from "../../Assets/Icons/Contacts.svg"

const SideMenu = () => {

    const navigate=useNavigate()
    const [menuClass, setMenuClass] = useState('main-menu-container');

   const handleMouseEnter = () => {
    const menuButtons = document.querySelectorAll('.main-menu-button');
    menuButtons.forEach(menuButton => menuButton.classList.add('hover'));
    setMenuClass('main-menu-container hover');

    const secondaryMenu = document.querySelectorAll('.secondary-menu-container');
    secondaryMenu.forEach(menuButton => menuButton.classList.add('hover'));


    const mainContent = document.querySelectorAll('.body-wrapper');
    mainContent.forEach(menuButton => menuButton.classList.add('hover'));

    const contactMainContent = document.querySelectorAll('.contact-body-wrapper');
    contactMainContent.forEach(menuButton => menuButton.classList.add('hover'));

    const secondaryMenuContact = document.querySelectorAll('.contact-secondary-menu-container');
    secondaryMenuContact.forEach(menuButton => menuButton.classList.add('hover'));

    
    const secondaryMenuCloud = document.querySelectorAll('.secondary-menu-container-cloud');
    secondaryMenuCloud.forEach(menuButton => menuButton.classList.add('hover'));
};

const handleMouseLeave = () => {
    const menuButtons = document.querySelectorAll('.main-menu-button');
    menuButtons.forEach(menuButton => menuButton.classList.remove('hover'));
    setMenuClass('main-menu-container');
    const secondaryMenu = document.querySelectorAll('.secondary-menu-container');
    secondaryMenu.forEach(menuButton => menuButton.classList.remove('hover'));

    const mainContent = document.querySelectorAll('.body-wrapper');
    mainContent.forEach(menuButton => menuButton.classList.remove('hover'));

    const contactMainContent = document.querySelectorAll('.contact-body-wrapper');
    contactMainContent.forEach(menuButton => menuButton.classList.remove('hover'));

    const secondaryMenuContact = document.querySelectorAll('.contact-secondary-menu-container');
    secondaryMenuContact.forEach(menuButton => menuButton.classList.remove('hover'));

    const secondaryMenuCloud = document.querySelectorAll('.secondary-menu-container-cloud');
    secondaryMenuCloud.forEach(menuButton => menuButton.classList.remove('hover'));
};

useEffect(()=>{
    var allButtons = document.querySelectorAll(".main-menu-button")
    allButtons.forEach(menuButton => menuButton.classList.remove('active'));
    var earth=document.querySelector("#main-menu-button-globe")
    var contact=document.querySelector("#main-menu-button-contacts")
    var cloud=document.querySelector("#main-menu-button-cloud")
    let path=window.location.pathname
    if(path==='/earth'){
        earth.classList.add('active');
    }else if(path==='/contacts'){
        contact.classList.add('active');
    }
    else if(path==='/cloud'){
        cloud.classList.add('active');
    }
   

},[])

    return (
        <div  className={menuClass} id='main-menu-container'
        onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className="dashboard-aryalogo">
                <img src="https://arya.earth/images/ARYA-logo-horizontal-white.svg"  alt=''/>
                <p className="arya-logo-text-sub">Quantum Safe | A.I. Powered</p>
            </div>

            <div className="dashboard-primarymenu-navigation">
                <span className="main-menu-button active" id="main-menu-button-globe" onClick={()=>navigate("/earth")}>
                   
                <svg className="main-menu-button-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" style={{ maxWidth: '100%', height: 'auto' }} xmlSpace="preserve">
                        <path d="M768.6,244.3c-20.1-47.6-49-90.4-85.7-127.1c-36.7-36.7-79.5-65.6-127.1-85.7c-48-20.3-98.9-30.8-151.4-31.4
	c-0.9,0-1.9-0.1-2.8,0c-0.5,0-1,0-1.5,0c-54,0-106.4,10.6-155.7,31.5c-47.6,20.1-90.4,49-127.1,85.7
	c-36.7,36.7-65.6,79.5-85.7,127.1C10.6,293.6,0,346,0,400c0,54,10.6,106.4,31.5,155.7c20.1,47.6,49,90.4,85.7,127.1
	c36.7,36.7,79.5,65.6,127.1,85.7C293.6,789.4,346,800,400,800c0.5,0,1,0,1.6,0c0.3,0,0.6,0,0.9,0c0.7,0,1.4,0,2.1-0.1
	c52.4-0.6,103.2-11.1,151.1-31.4c47.6-20.1,90.4-49,127.1-85.7c36.7-36.7,65.6-79.5,85.7-127.1C789.4,506.4,800,454,800,400
	C800,346,789.4,293.6,768.6,244.3z M717.5,360h-119c-4.6-61-19.8-120-45.3-175.9c-14.1-30.9-31-60-50.6-87.3
	C616.7,135.6,702.1,237.1,717.5,360z M518.2,360H281.8c9-98,50.6-190.3,118.2-262C467.5,169.7,509.2,262.1,518.2,360z M297.4,96.9
	c-19.2,26.7-35.8,55.3-49.8,85.6c-26,56.3-41.4,115.9-46,177.5h-119C97.9,237.1,183.3,135.6,297.4,96.9z M82.5,440h119
	c4.6,61.6,20.1,121.2,46,177.5c14,30.3,30.6,58.9,49.8,85.6C183.3,664.4,97.9,562.9,82.5,440z M400,702
	c-67.6-71.6-109.2-164-118.2-262h236.3C509.2,537.9,467.5,630.3,400,702z M502.6,703.1c19.6-27.2,36.5-56.4,50.6-87.3
	C578.7,560,593.9,501,598.5,440h119C702.1,562.9,616.7,664.4,502.6,703.1z"></path>
                    </svg>                      <p className="main-menu-button-text">ARYA Earth</p>
                </span>
                <span className="main-menu-button" id="main-menu-button-contacts" onClick={()=>navigate("/contacts")} >
                    <svg className="main-menu-button-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" style={{ maxWidth: '100%', height: 'auto' }} xmlSpace="preserve">
                        <path className="st0" d="M794.2,517.8c-30.3-44.4-77.7-84-139.1-104C683,384,700,344,700,300c0-92.1-74.6-166.7-166.7-166.7
	S366.6,208,366.6,300c0,54.4,26.1,102.7,66.5,133.2c-33.5,18.1-61.3,42.3-82.8,69.4c-17.3-17.6-38.1-33.3-62.2-45.2
	c27.9-27.3,45.2-65.3,45.2-107.4c0-82.8-67.2-150-150-150s-150,67.2-150,150c0,42.1,17.3,80.1,45.3,107.4
	c-28,13.9-51.6,32.8-70.3,54c-12.2,13.8-10.8,34.8,3,47c13.8,12.2,34.8,10.8,47-3c27.4-31.1,69.8-55.3,125-55.3
	c55.2,0,97.6,24.2,125,55.3c2.1,2.4,4.5,4.5,7.1,6.1c-10.1,24.2-15.4,48.8-15.4,71.9c0,18.4,14.9,33.3,33.3,33.3
	c18.4,0,33.3-14.9,33.3-33.3c0-64.2,72.2-166.7,200-166.7c80.6,0,140,40.9,172.5,88.6c10.3,15.2,31.1,19.2,46.3,8.8
	C800.6,553.8,804.6,533,794.2,517.8z M183.2,433.3c-46,0-83.3-37.3-83.3-83.3s37.3-83.3,83.3-83.3s83.3,37.3,83.3,83.3
	S229.2,433.3,183.2,433.3z M533.3,400c-55.2,0-100-44.8-100-100s44.8-100,100-100s100,44.8,100,100S588.5,400,533.3,400z"></path>
                    </svg>
                    <p className="main-menu-button-text">Contacts</p>
                </span>
                <span className="main-menu-button" id="main-menu-button-cloud"  onClick={()=>navigate("/cloud")}>
                    <svg className="main-menu-button-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style={{ enableBackground: 'new 0 0 250 250', maxWidth: '100%', height: 'auto' }} xmlSpace="preserve">
                        <path className="st0" d="M239.1,134.8c-6.2-7.2-14.6-12.5-23.9-15c-0.4-25.4-21.2-45.9-46.8-45.9c-1.6,0-2.9,0.3-4.3,0.6
	c-5.1-10.1-12.8-18.7-22.1-24.9c-10.3-6.8-22.2-10.5-34.4-10.5c-35,0-63.6,28.3-64.1,63.2C17.8,108.1,0,129.8,0,155.4
	c0,30.6,24.9,55.4,55.4,55.4h147.8c25.8,0,46.7-21,46.7-46.7C250,153.2,246.2,143.1,239.1,134.8z M68,109.9L68,109.9
	c-0.4-2.2-0.6-4.4-0.6-6.6c0-22,17.9-40,39.9-40.2h0.4c17.7,0,33.1,11.4,38.4,28.3c2,6.3,8.7,9.8,15,7.9c0.1,0,0.2-0.1,0.3-0.1
	c2.3-0.8,4.7-1.2,7.2-1.2c12.8,0,22.8,10,22.8,22.8c0,1.9-0.3,3.9-0.8,5.7c-0.8,3.1-0.4,6.3,1.2,9.1c1.6,2.8,4.2,4.7,7.2,5.6
	c1.4,0.4,2.8,0.5,4.2,0.4l0.2,0c12.8,0.1,22.7,10.1,22.7,22.8c0,12.8-10,22.8-22.8,22.8H55.4c-17.4,0-31.5-14.1-31.5-31.5
	s14.1-31.5,31.5-31.5l0.3,0h0.1c6.6,0.2,12.1-5,12.3-11.6C68.2,111.5,68.2,110.7,68,109.9z"></path>
                    </svg>
                    <p className="main-menu-button-text">A.I. Cloud</p>
                </span>
                <span className="main-menu-button"  id="main-menu-button-reports" style={{display:"none"}} href="https://arya.earth/platform/cloud/">
                    <svg className="main-menu-button-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" style={{ maxWidth: '100%', height: 'auto' }} xmlSpace="preserve">
                        <path className="st0" d="M163.7,266.7h45.9c16.8,0,30.4,13.6,30.4,30.4v392.5c0,16.8-13.6,30.4-30.4,30.4h-45.9
	c-16.8,0-30.4-13.6-30.4-30.4V297.1C133.3,280.3,146.9,266.7,163.7,266.7z M560,430.4v259.2c0,16.8,13.6,30.4,30.4,30.4h45.9
	c16.8,0,30.4-13.6,30.4-30.4V430.4c0-16.8-13.6-30.4-30.4-30.4h-45.9C573.6,400,560,413.6,560,430.4z M377.1,720h45.9
	c16.8,0,30.4-13.6,30.4-30.4V110.4c0-16.8-13.6-30.4-30.4-30.4h-45.9c-16.8,0-30.4,13.6-30.4,30.4v579.2
	C346.7,706.4,360.3,720,377.1,720z"></path>
                    </svg>
                    <p className="main-menu-button-text">Reports</p>
                </span>
            </div>

            <div className="dashboard-profile-wrapper">
                <div className="menu-profile-button">
                    <svg className="menu-profile-button-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style={{ enableBackground: 'new 0 0 250 250', maxWidth: '100%', height: 'auto' }} xmlSpace="preserve">
                        <path className="st0" d="M125,81.5c-24,0-43.5,19.5-43.5,43.5c0,24,19.5,43.5,43.5,43.5c24,0,43.5-19.5,43.5-43.5 C168.5,101,149,81.5,125,81.5z M125,146.7c-12,0-21.7-9.7-21.7-21.7s9.7-21.7,21.7-21.7c12,0,21.7,9.7,21.7,21.7 S137,146.7,125,146.7z"></path>
                        <path className="st0" d="M233.3,141.2l-3.5-2.9c-8.4-7-8.4-19.5,0-26.5l3.5-2.9c11.8-9.9,14.8-26.7,6.9-40l-7.8-13 c-7.8-13.1-24-18.8-38.4-14l-4.3,1.5c-11.1,3.7-22.4-3.1-24.4-13.4l-0.9-4.3C161.5,10.5,148,0,132.8,0h-15.5 C102,0,88.5,10.5,85.6,25.6L84.7,30c-2,10.3-13.4,17.1-24.4,13.4L56,41.9c-14.4-4.9-30.5,0.8-38.4,14l-7.8,13 c-7.9,13.3-4.9,30.1,6.9,40l3.5,2.9c8.4,7,8.4,19.5,0,26.5l-3.5,2.9c-11.8,9.9-14.8,26.7-6.9,40l7.8,13c7.8,13.1,24,18.8,38.4,14 l4.3-1.5c11.1-3.7,22.4,3.1,24.4,13.4l0.9,4.3c3,15.1,16.5,25.6,31.7,25.6h15.5c15.2,0,28.7-10.5,31.7-25.6l0.9-4.3 c2-10.3,13.4-17.1,24.4-13.4l4.3,1.5c14.4,4.9,30.5-0.8,38.4-14l7.8-13C248.1,167.8,245.1,151,233.3,141.2z M215.9,154.9l3.5,2.9 c3.7,3.1,4.5,8.2,2.2,12.2l-7.8,13c-2.4,4.1-7.8,6.2-12.8,4.5l-4.3-1.5c-22.9-7.7-48,5.9-52.7,29.8l-0.9,4.3 c-0.9,4.5-5,8.1-10.4,8.1h-15.5c-5.3,0-9.5-3.6-10.4-8.1l-0.9-4.4c-4.7-23.9-29.8-37.5-52.7-29.8l-4.3,1.5 c-5,1.7-10.3-0.4-12.8-4.5l-7.8-13c-2.4-4-1.6-9,2.2-12.2l3.5-2.9c18.8-15.7,18.8-44.1,0-59.8l-3.5-2.9c-3.7-3.1-4.5-8.2-2.2-12.2 l7.8-13c2.4-4.1,7.8-6.2,12.8-4.5l4.3,1.5c22.9,7.7,48-5.9,52.7-29.8l0.9-4.3c0.9-4.5,5-8.1,10.4-8.1h15.5c5.3,0,9.5,3.6,10.4,8.1 l0.9,4.3c4.7,23.9,29.8,37.5,52.7,29.8l4.3-1.5c5-1.7,10.3,0.4,12.8,4.5l7.8,13c2.4,4,1.6,9-2.2,12.2l-3.5,2.9 C197.1,110.8,197.1,139.2,215.9,154.9z"></path>
                    </svg>
                    <p className="menu-profile-button-text">Settings</p>
                </div>
                <div className="menu-profile-button">
                    <svg className="menu-profile-button-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 250 250" style={{ enableBackground: 'new 0 0 250 250', maxWidth: '100%', height: 'auto' }} xmlSpace="preserve">
                        <path className="st0" d="M38.8,136.4h131.7c6.3,0,11.4-5.1,11.4-11.4s-5.1-11.4-11.4-11.4H38.8l14.7-14.7c4.4-4.4,4.4-11.6,0-16.1
		c-4.4-4.4-11.6-4.4-16.1,0L3.3,117c-0.3,0.3-0.5,0.5-0.7,0.8c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.3,0.4-0.5,0.7
		c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.2-0.3,0.5-0.4,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.3-0.3,0.6-0.4,0.9c0,0,0,0.1,0,0.1
		c-0.1,0.3-0.2,0.6-0.3,0.9c0,0,0,0.1,0,0.1c-0.1,0.3-0.2,0.6-0.2,1c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.5-0.1,0.8c0,0.4-0.1,0.8-0.1,1.1
		s0,0.8,0.1,1.1c0,0.3,0.1,0.5,0.1,0.8c0,0.1,0,0.2,0,0.3c0.1,0.3,0.1,0.6,0.2,1c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.6,0.3,0.9
		c0,0,0,0.1,0,0.1c0.1,0.3,0.3,0.6,0.4,0.9c0,0,0,0.1,0.1,0.2c0.1,0.3,0.3,0.5,0.5,0.8c0,0.1,0.1,0.1,0.1,0.2
		c0.2,0.2,0.3,0.5,0.5,0.7c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.3,0.5,0.6,0.7,0.8l34.1,34.1c2.2,2.2,5.1,3.3,8,3.3s5.8-1.1,8-3.3
		c4.4-4.4,4.4-11.6,0-16.1L38.8,136.4z"></path>
                        <path className="st0" d="M157,32c-31,0-59.9,15.4-77.2,41.2c-3.5,5.2-2.1,12.3,3.1,15.8c5.2,3.5,12.3,2.1,15.8-3.1
		c13.1-19.5,34.9-31.1,58.3-31.1c38.7,0,70.2,31.5,70.2,70.2s-31.5,70.2-70.2,70.2c-23.4,0-45.1-11.6-58.2-31
		c-3.5-5.2-10.6-6.6-15.8-3.1c-5.2,3.5-6.6,10.6-3.1,15.8c17.3,25.7,46.1,41,77.1,41c51.3,0,93-41.7,93-93C250,73.7,208.3,32,157,32
		z"></path>
                    </svg>
                    <p className="menu-profile-button-text" onClick={()=>navigate("/")}>Log Out</p>
                </div>
                <div className="dashboard-profile">
                </div>

            </div>



      
        </div>
    )
}

export default SideMenu