import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SideMenu from './Components/SideMenu/SideMenu';

const Login = lazy(() => import('./Pages/Login/Login'));
const Earth = lazy(() => import('./Pages/Earth/Earth'));
const Contacts = lazy(() => import('./Pages/Contacts/Contacts'));
const Cloud = lazy(() => import('./Pages/AICloud/Index'))

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
    {/* <SideMenu/> */}
      <Routes>
      <Route path="/earth" element={<Earth />} />
        <Route path="/" element={<Login />} />
       <Route path="/contacts" element={<Contacts />} />
       <Route path="/cloud" element={<Cloud />} />
      </Routes>
    </Suspense>
  </Router>
);

export default App;
